import React from "react";

const Origin = () => {
  return (
    <svg
      width="48"
      height="52"
      viewBox="0 0 48 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.0482 10.674H33.4961C33.3313 5.31148 28.9171 1 23.5159 1C18.1148 1 13.7004 5.31106 13.5365 10.674H10.9844C5.47876 10.674 1 15.1527 1 20.6584C1 26.1045 14.1872 33.9968 19.7784 33.9968V33.3758C14.5127 33.3758 1.62076 25.7136 1.62076 20.6582C1.62076 15.4949 5.82119 11.2948 10.9842 11.2948H14.1532V10.9841C14.1523 5.82162 18.3527 1.62108 23.5157 1.62108C28.6787 1.62108 32.8791 5.82151 32.8791 10.9845V11.2952H36.0481C41.2114 11.2952 45.4115 15.4956 45.4115 20.6586C45.4115 25.8507 33.2784 33.3763 28.1334 33.3763V33.9972C33.6062 33.9972 46.0333 26.2464 46.0333 20.6588C46.0333 15.1536 41.5547 10.6741 36.049 10.6741L36.0482 10.674Z"
        fill="#B7BDA4"
        stroke="#B7BDA4"
        strokeWidth="2"
      />
      <path
        d="M23.8263 26.505V23.4305L28.3466 19.1244L27.9186 18.6748L23.8262 22.5721V16.8544L27.0771 16.1248L26.9415 15.5188L23.8262 16.2176V13.623H23.2048V22.4524L20.0142 18.699L19.5417 19.1008L23.2047 23.4114V26.9444H18.0186V27.5653H23.2047V51H23.8261V27.3833L26.07 25.1385L25.6308 24.6993L23.8263 26.505Z"
        fill="#B7BDA4"
        stroke="#B7BDA4"
        strokeWidth="2"
      />
    </svg>
  );
};

export default Origin;
