import React from "react";

const Growth = () => {
  return (
    <svg
      width="54"
      height="52"
      viewBox="0 0 54 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.9754 29.3258C5.49708 29.2538 5.09147 28.9174 4.93865 28.4459C4.76318 27.9049 4.96236 27.3123 5.42865 26.9868L5.42938 26.9863L39.0554 3.64235L36.7214 3.22554L36.719 3.2251C36.3807 3.16217 36.0818 2.96712 35.888 2.6838C35.6933 2.39959 35.6207 2.04985 35.6848 1.71245L36.0286 1.7777M5.9754 29.3258L43.5774 3.10154C43.5775 3.10125 43.5775 3.10096 43.5776 3.10067C43.6088 2.93715 43.6083 2.76886 43.576 2.60551L43.5757 2.60437C43.5421 2.43749 43.4757 2.27992 43.3826 2.13989L43.3827 2.13983L43.3776 2.13267C43.2783 1.99112 43.1505 1.87131 43.0014 1.78164C42.8751 1.7017 42.7336 1.64675 42.5848 1.62136C42.5846 1.62132 42.5844 1.62128 42.5841 1.62123L37.1828 0.678581L37.1529 0.673369H37.151C36.464 0.567737 35.8135 1.02598 35.6846 1.71319L36.0286 1.7777M5.9754 29.3258L5.98421 29.341L6.18655 29.3405C6.45059 29.34 6.7073 29.2585 6.92326 29.1082L6.92331 29.1081L40.4924 5.73637M5.9754 29.3258L40.4924 5.73637M36.0286 1.7777C36.1241 1.26835 36.6124 0.931604 37.1222 1.02337L36.783 2.88099C36.5363 2.8351 36.3182 2.69283 36.1768 2.48614C36.0349 2.27904 35.9819 2.02395 36.0286 1.7777ZM40.4924 5.73637L40.0688 8.07018L40.0685 8.07153C40.0086 8.4093 40.0847 8.75687 40.2816 9.03866M40.4924 5.73637L42.6346 8.5039M40.2816 9.03866C40.2816 9.03862 40.2815 9.03858 40.2815 9.03854L40.5685 8.83819L40.2816 9.03866ZM40.2816 9.03866C40.4779 9.31981 40.778 9.51132 41.1155 9.57132L41.1459 9.57672H41.1767H41.3445M41.3445 9.57672C41.9796 9.58461 42.5263 9.12956 42.6346 8.5039M41.3445 9.57672C41.3436 9.57671 41.3426 9.5767 41.3417 9.57669L41.3468 9.22672V9.57672H41.3445ZM42.6346 8.5039L42.6345 8.50437L42.2897 8.44419L42.6346 8.5039Z"
        fill="#B7BDA4"
        stroke="#B7BDA4"
        strokeWidth="0.7"
      />
      <path
        d="M33.5018 24.6356L33.2543 24.8831L33.5018 24.6356C33.2593 24.3931 32.9303 24.2569 32.5875 24.2569H23.1584C22.4444 24.2569 21.8655 24.8358 21.8655 25.5498V48.7726H18.7938V34.9789C18.7938 34.6361 18.6577 34.3071 18.4152 34.0647C18.1727 33.8222 17.8438 33.686 17.5009 33.686H8.07183C7.35783 33.686 6.77892 34.2649 6.77892 34.9789V48.7726H1.94291C1.22891 48.7726 0.65 49.3515 0.65 50.0655C0.65 50.7795 1.22891 51.3584 1.94291 51.3584H51.9172C52.6312 51.3584 53.2101 50.7795 53.2101 50.0655C53.2101 49.3515 52.6312 48.7726 51.9172 48.7726H48.967V14.2349C48.967 13.8921 48.8308 13.5631 48.5883 13.3206C48.3459 13.0782 48.0169 12.942 47.6741 12.942H38.245C37.531 12.942 36.9521 13.5209 36.9521 14.2349V48.7726H33.8804V25.5498C33.8804 25.207 33.7442 24.878 33.5018 24.6356ZM9.36528 48.7726V36.2718H16.2086V48.7726H9.36528ZM24.4518 48.7726V26.8427H31.2951V48.7726H24.4518ZM39.5384 48.7726V15.5278H46.3817V48.7726H39.5384Z"
        fill="#B7BDA4"
        stroke="#B7BDA4"
        strokeWidth="0.7"
      />
    </svg>
  );
};

export default Growth;
